import { Link } from 'gatsby';
import React from 'react';
import { imageUrlFor } from '../lib/image-url';
import styles from './desktop-navbar.module.css';

const DesktopNavbar = ({ logo }) => (
  <>
    <div className={styles.root}>
      <div className={styles.navContent}>
        <div className={styles.menuItem}>
          <Link className={styles.itemLink} to="/">
            hjem
          </Link>
        </div>
        <div className={styles.menuItem}>
          <Link className={styles.itemLink} to="/produkter">
            produkter
          </Link>
        </div>
        <div className={styles.logoContainer}>
          <Link className={styles.logoLink} to="/">
            <img className={styles.logo} src={imageUrlFor(logo).width(300)} />
          </Link>
        </div>
        <div className={styles.menuItem}>
          <Link className={styles.itemLink} to="/kontakt">
            kontakt
          </Link>
        </div>
        <div className={styles.menuItem}>
          <Link className={styles.itemLink} to="/om">
            om oss
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default DesktopNavbar;
