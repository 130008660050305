import React from 'react'
import {StaticQuery, graphql} from 'gatsby'

import styles from './compact-store-hours.js'

function CompactStoreHours () {
  return (
    <StaticQuery
        query={compactStoreHoursQuery}
        render={data => (
            <div className={styles.root}>
              <div className={styles.column}>
                <p className={styles.columnHeader}>Åpningstider</p>
                <p>Mandag – fredag: {data.storeHours.edges[0].node.monday}</p>		 
                <p>Langåpent torsdag: {data.storeHours.edges[0].node.thursday}</p>		  
                <p>Lørdag: {data.storeHours.edges[0].node.saturday}</p>		          
              </div>
            </div>
        )} 
    />
  )
}

export default CompactStoreHours


const compactStoreHoursQuery = graphql`
  query DefaultCompactStoreHoursQuery {
    storeHours: allSanityStoreHours(limit: 1) {
        edges {
          node {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
          }
        }
      }
  }
`