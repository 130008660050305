import { Link } from 'gatsby';
import React from 'react';
import styles from './mobile-menu.module.css';

const MobileMenu = ({ logo, toggled }) => (
  <>
    <div className={`${styles.root} ${toggled ? styles.active : ''}`}>
      <ul className={styles.menuItems}>
        <li className={styles.menuItem}>
          <Link to="/">Forside</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/produkter">Produkter</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/kontakt">Kontakt</Link>
        </li>
        <li className={styles.menuItem}>
          <Link to="/om">Om oss</Link>
        </li>
      </ul>
    </div>
  </>
);

export default MobileMenu;
