import React from "react";
import styles from "./hamburger.module.css";

export const Hamburger = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.button}>
      <svg className={styles.root} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Meny</title>
        <rect x="4" y="18" width="16" height="2"></rect>
        <rect x="4" y="11" width="16" height="2"></rect>
        <rect x="4" y="4" width="16" height="2"></rect>
      </svg>
    </button>
  );
};

export const Close = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.button}>
      <svg className={styles.root} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <title>Lukk</title>
        <polygon points="18.41 7 17 5.59 12 10.59 7 5.59 5.59 7 10.59 12 5.59 17 7 18.41 12 13.41 17 18.41 18.41 17 13.41 12 18.41 7"></polygon>
      </svg>
    </button>
  );
};
