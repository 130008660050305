import { Link } from 'gatsby';
import React, { useState } from 'react';
import { imageUrlFor } from '../lib/image-url';
import MobileMenu from './mobile-menu';
import styles from './mobile-navbar.module.css';
import { Hamburger, Close } from './hamburger';

const MobileNavbar = props => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={styles.root}>
        <Link className={styles.logoLink} to="/">
          <img className={styles.logo} src={imageUrlFor(props.logo).width(200)} />
        </Link>
        <div className={styles.hamburgerWrapper}>
          {open ? <Close onClick={() => setOpen(false)} /> : <Hamburger onClick={() => setOpen(true)} />}
        </div>
      </div>
      <MobileMenu toggled={open} />
    </>
  );
};

MobileNavbar.defaultProps = {
  logo: ''
};

export default MobileNavbar;
