import React from 'react';
import MobileNavbar from './mobile-navbar';
import DesktopNavbar from './desktop-navbar';
import styles from './header.module.css';

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, logo }) => (
  <header className={styles.root}>
    <MobileNavbar logo={logo} />
    <DesktopNavbar logo={logo} />
  </header>
);

export default Header;
