import {Link as a} from 'gatsby'
import React from 'react'
import {imageUrlFor} from '../lib/image-url'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import CompactStoreHours from './compact-store-hours';

import styles from './footer.module.css'

function Footer ({logo, siteTitle, storeHours}) {
  return (
    <footer className={styles.root}>
        <div className={styles.content}>
            <div className={styles.column}>
                <p className={styles.columnHeader}>{siteTitle}</p>
                <p>Ulefossvegen 42</p>
                <p>3730 Skien</p>
            </div>
            <div className={styles.column}>
                <p className={styles.columnHeader}>Følg oss</p>
                <a className={styles.link} href="https://www.facebook.com/GuntorInterior"><FaFacebookSquare className={styles.socialIcon} /> Guntor Interiør på Facebook</a>
                <a className={styles.link} href="https://www.instagram.com/guntorinterior/"><FaInstagram className={styles.socialIcon} /> Guntor Interiør på Instagram</a>
            </div>
            <div className={styles.column}>
                <CompactStoreHours />
            </div>


        </div>


    </footer>
  )
}

export default Footer
