import React from 'react'
import Header from './header'
import CookieConsent from "react-cookie-consent";
import '../styles/layout.css'
import styles from './layout.module.css'
import Footer from './footer'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, logo, storeHours}) => (
  <>
    <Header logo={logo} siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P75TD8T" height="0" width="0" style={{display:'none', visibility: 'hidden'}}></iframe></noscript>
    <div className={styles.content}>{children}</div>
    <Footer logo={logo} siteTitle={siteTitle}  storeHours={storeHours} />
    <CookieConsent 
      style={{ background: "#000" }}
      buttonStyle={{ color: "#000", backgroundColor: "#fff", fontSize: "13px" }}
      buttonText="Jeg godtar"
      onAccept ={() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event' : 'cookie-consent-given'
        });
      }}
      expires={150}>
        Vi bruker cookies for å gi deg en bedre opplevelse.
      </CookieConsent>
  </>
)

export default Layout
